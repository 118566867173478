import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';

function App() {
	const routes = [
		{
			path: '/',
			Component: lazy(() => import('./pages/Home/Home')),
		},
		{
			path: '/programmes',
			Component: lazy(() => import('./pages/Programs/Programs')),
		},
		{
			path: '/learning',
			Component: lazy(() => import('./pages/ComingSoon/ComingSoon')),
		},
		{
			path: '/events',
			Component: lazy(() => import('./pages/ComingSoon/ComingSoon')),
		},
		{
			path: '/contact',
			Component: lazy(() => import('./pages/Contact/Contact')),
		},
		{
			path: '/team',
			Component: lazy(() => import('./pages/Team/Team')),
		},
		{
			path: '*',
			Component: lazy(() => import('./pages/Error')),
		},
	];

	return (
		<BrowserRouter>
			<NavBar />
			<Suspense fallback={<span>Loading...</span>}>
				<Routes>
					{routes.map(({ path, Component }) => (
						<Route path={path} element={<Component />} />
					))}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
