/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

export default function NavBar() {
	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-light">
			<div className="container container-fluid">
				<Link to="/" className="text-decoration-none">
					<a className="navbar-brand" href="#">
						Kids Pitstop
					</a>
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarColor01"
					aria-controls="navbarColor01"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarColor01">
					<ul className="navbar-nav me-auto">
						<Link to="/" className="text-decoration-none">
							<li className="nav-item">
								<a className="nav-link" href="#">
									Home
								</a>
							</li>
						</Link>
						<Link to="/programmes" className="text-decoration-none">
							<li className="nav-item">
								<a className="nav-link" href="#">
									Programmes
								</a>
							</li>
						</Link>
						<Link to="/team" className="text-decoration-none">
							<li className="nav-item">
								<a className="nav-link" href="#">
									Our Crew
								</a>
							</li>
						</Link>
						<Link to="/contact" className="text-decoration-none">
							<li className="nav-item">
								<a className="nav-link" href="#">
									Contact
								</a>
							</li>
						</Link>
					</ul>
				</div>
			</div>
		</nav>
	);
}
